import { useProfileDeps } from '../../useDeps'

export function useChangeNicknameForm() {
  const NICKNAME_REGEX = /^[a-zA-Z-_0-9]{3,25}$/

  const { t } = useI18n()
  const stFetch = useRawStFetch()
  const isLoading = ref(false)
  const { user } = useProfileDeps()
  const router = useRouter()
  const { open } = useToast()

  const nickname = ref('')
  watchEffect(() => {
    nickname.value = user.value?.nickname ?? ''
  })

  const errorMessage = ref()
  watch(nickname, () => {
    errorMessage.value = undefined
  })

  function validate() {
    return NICKNAME_REGEX.test(nickname.value)
      ? {
          isValid: true,
        }
      : {
          isValid: false,
          message: t('nicknameChange.nicknameRequirementViolation'),
        }
  }

  async function handleSubmit() {
    const { isValid, message } = validate()
    if (!isValid) {
      errorMessage.value = message
      return
    }

    isLoading.value = true
    const { error } = await stFetch('/user/nickname/set', {
      method: 'post',
      body: {
        nickname: nickname.value,
      },
    })
    isLoading.value = false

    if (error) {
      errorMessage.value =
        error.error === 'NICKNAME_REQUIREMENT_VIOLATION'
          ? t('nicknameChange.nicknameRequirementViolation')
          : t('nicknameChange.somethingWentWrong')

      return
    }

    if (user.value) user.value.nickname = nickname.value
    router.replace({ query: {} })
    open({
      label: t('mobileProfile.changeNicknameSucсess'),
      type: 'positive',
    })
  }

  return {
    nickname,
    handleSubmit,
    errorMessage,
    isLoading,
  }
}
